<template>
  <div class="palmistry">
    <div class="palmistry-body" :class="{ is_full: isFull }">
      <div class="win-btn">
        <i @click="close" v-if="showClose" class="xszy-icon icon-close"></i>
        <i @click="fullFun(false)" v-if="isFull" class="xszy-icon icon-zuixiaohua"></i>
        <i @click="fullFun(true)" v-else class="xszy-icon icon-quanpingzuidahua"></i>
      </div>
      <div class="mark" v-if="!medicalRecordScan.length">
        <uEmpty />
      </div>
      <div class="container" id="container2"></div>
      <div class="fail" v-if="isFail">解读失败</div>
      <div class="info_win">
        <div class="info_border" v-if="infoList.length">
          <div class="title"></div>
          <vue-scroll :ops="ops">
            <div class="content">
              <div class="item" v-for="(item, index) in infoList" :key="index">
                <div class="item_title">{{ item.label }}</div>
                <div class="item_con" v-html="item.values"></div>
              </div>
              <div class="item">
                <div class="item_title">掌型：{{ handData.palmName }}</div>
                <div class="item_con zw" v-html="newlineToBr(handData.unscramble)"></div>
              </div>
            </div>
          </vue-scroll>
          <div class="foot"></div>
        </div>
      </div>
      <div class="btn_g">
        <template v-if="isPic">
          <div class="btn zoom_3" @click="showNiceFun">
            <i v-if="showNice" class="xszy-icon icon-yanjing"></i>
            <i v-else class="xszy-icon icon-yanjing1"></i>
          </div>
          <div class="btn zoom_1" @click="zoom(1)">
            <i class="xszy-icon icon-zoomin"></i>
          </div>
          <div class="btn zoom_2" @click="zoom(0)">
            <i class="xszy-icon icon-zoomout"></i>
          </div>
        </template>
      </div>
      <div class="info">
        <span>解读时间:{{ activeItem.crDt }}</span>
      </div>
      <i v-show="isLeft" class="pagechange xszy-icon icon-left" @click="setPageIndex(-1)"></i>
      <i v-show="isRight" class="pagechange xszy-icon icon-right" @click="setPageIndex(1)"></i>
      <Page @on-change="pageChange" :current="pageIndex" class="page" :page-size="1" :total="medicalRecordScan.length"
        size="small" />
    </div>
  </div>
</template>
<script>

import { getImgBase64 } from './common';
import Hander from "./Hander2.js";
import { Scene } from "spritejs";
import vueScroll from 'vuescroll';
import uEmpty from './uEmpty.vue';
export default {
  components: {
    vueScroll,
    uEmpty
  },
  props: {
    patientCode: {
      type: String,
      default: ""
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFull: false,
      ops: {
        bar: {
          background: '#ccc'
        }
      },
      handData: {},
      showNice: false,
      infoList: [],
      scene: null,
      layer: null,
      isPic: false, //有图
      hander: null,
      activeItem: {},
      medicalRecordScan: [],
      pageIndex: 1,
      isFail: false, //解读失败否
    };
  },
  computed: {
    isRight() {
      return (this.pageIndex + 1) <= this.medicalRecordScan.length;
    },
    isLeft() {
      return (this.pageIndex - 1) > 0;
    }
  },
  methods: {
    close() {
      this.$emit("close")
    },
    fullFun(type) {
      this.isFull = type;
      if (this.layer) this.layer.remove();
      setTimeout(() => {
        this.clickPic();
      }, 300);
    },
    setPageIndex(nb) {
      let index = tis.pageIndex + nb;
      if (index > 0 && index <= this.medicalRecordScan.length) {
        this.pageIndex = index;
      }
    },
    pageChange(index) {
      this.pageIndex = index;
      this.activeItem = this.medicalRecordScan[this.pageIndex - 1];
      this.clickPic();
    },
    newlineToBr(str) {
      if (str) return str.replace(/\n/g, '<br>');
    },
    async clickPic() {
      this.isFail = false;
      if (this.layer) this.layer.remove();
      this.createHander();

      // 如果有图片数据的话就直接读取不需要调接口
      if (!this.activeItem.base64) {
        let url = this.cloudPic + "/hand/" + this.activeItem.picUrl;
        let imgdata = await getImgBase64(url).catch(err => console.log(err));
        if (!imgdata) {
          this.$Message.error("无效图片链接，不能解读");
          if (this.layer) this.layer.remove();
          this.layer = null;
          this.isFail = true;
          return;
        }
        this.activeItem.base64 = imgdata.base64;
      }
      // 如果有手相数据的话就直接读取不需要调接口
      if (this.activeItem.handData) {
        this.isPic = true;
        this.init();
      } else {
        this.api2('getHandUnscrambleByAdiId', {
          aidId: this.activeItem.aidId, //	明细主键Id
          patientCode: this.patientCode, //	患者编码
        }).then(res => {
          this.activeItem.handData = res.result;
          this.isPic = true;
          this.init();
        })
      }
    },
    getData() {
      let obj = {
        patientCode: this.patientCode,
        picType: 1,
        handFlag: 0,
      };
      this.api2("queryMedicalRecordScan", obj).then(res => {
        this.medicalRecordScan = res.result;
        // 有手相图片直接解读第一张
        if (this.medicalRecordScan.length) {
          this.activeItem = this.medicalRecordScan[0];
          this.clickPic();
        } else {
          this.isPic = false;
        }
      });
    },

    cancel() {
      this.isPic = true;
      if (this.layer) this.layer.remove();
      this.layer = null;
    },

    createHander() {
      let container = this.$el.querySelector("#container2");
      this.scene = new Scene({
        container,
        mode: "stickyWidth"
      });
      this.layer = this.scene.layer();
      this.hander = new Hander(this.layer);
    },

    init() {
      if (!this.activeItem.handData.nineHillsPlains.length) {
        this.$Message.warning("无解读数据哦")
        return;
      }

      setTimeout(async () => {
        this.infoList = [];
        let obj = {
          graphical: [], //图形
          texture: [], //纹路
          speckle: [], //斑点
          ninePoint: [] //九区中点和名称
        };

        // 整理区块名称和区块中心点坐标
        let nine = this.activeItem.handData.nineHillsPlains;
        // 合并手指的数据
        // nine = nine.concat(this.activeItem.handData.finger)

        obj.ninePoint = nine.filter(i => i.graphical).map(i => {
          let p = i.graphical.coordinate;
          let point = [
            p.reduce((sum, j) => {
              return (sum += j[0]);
            }, 0) / p.length,
            p.reduce((sum, j) => {
              return (sum += j[1]);
            }, 0) / p.length
          ];
          return {
            label: i.regionName,
            point
          };
        });

        this.setPoint(nine, obj);
        this.infoList = this.toRepeat(this.infoList);

        // 处理三大主级数据
        obj.threeMainLine = this.activeItem.handData.threeMainLine.map(i => {
          return {
            text: i.lineName,
            points: i.graphical.coordinate
          };
        });

        Object.assign(obj, {
          base64: this.activeItem.base64,
          imageHeight: 3264,
          imageWidth: 2448
        });

        this.hander.setData(obj);
        this.showInfo = true;
      }, 500);
    },

    // 是否显视区块名称
    showNiceFun() {
      this.showNice = !this.showNice;
      this.hander.setNiceTipVisibility(this.showNice ? 1 : 0);
    },

    // 缩放
    zoom(isEnlarge) {
      let pos = {
        x: this.layer.width / 2,
        y: this.layer.height / 2
      };
      this.hander.zoom(pos, isEnlarge);
    },

    setPoint(list, obj) {
      list.forEach(i => {
        if (i.graphical) {
          obj.graphical.push({
            text: i.regionName || "",
            points: i.graphical.coordinate
          });
        }

        // 纹
        if (i.texture) {
          i.texture.forEach(j => {
            if (j.unscramble) {
              this.infoList.push({
                label: i.regionName,
                value: j.unscramble,
                values: []
              });
            }
            obj.texture.push({
              text: j.unscramble || j.textureName,
              points: j.graphical.coordinate
            });
          });
        }

        // 斑点
        if (i.speckle) {
          i.speckle.forEach(j => {
            if (j.unscramble) {
              this.infoList.push({
                label: i.regionName,
                value: j.unscramble,
                values: []
              });
            }
            let p = j.graphical.coordinate;
            obj.speckle.push({
              text: j.unscramble || j.speckleName,
              // 获取斑点中心点
              points: [
                p[0][0] + (p[1][0] - p[0][0]) / 2,
                p[0][1] + (p[1][1] - p[0][1]) / 2
              ]
            });
          });
        }

        if (i.subRegion && i.subRegion.length) {
          this.setPoint(i.subRegion, obj, i.regionName);
        }
      });
    },

    toRepeat(list) {
      let newList = [];
      list.forEach(i => {
        let item = newList.find(j => j.label == i.label);
        let value = i.value.replace(/^\d+、*/, "");
        if (item) {
          if (!item.values.find(x => x == i.value) && i.value)
            item.values.push(value);
        } else {
          newList.push({
            label: i.label,
            values: [value]
          });
        }
      });
      newList.forEach(i => {
        i.values = i.values
          .map((j, index) => {
            return (i.values[index] = `${index + 1}、${j}`);
          })
          .join("<br/>");
      });
      return newList;
    },
  },
  mounted() {
    this.getData();
  }
};
</script>

<style >
@import url("./iconfont.css");
@import url("./camera3.css");
</style>