import common from '@/common.js';
import api2 from '../../api/api2.js'
import Cookies from "js-cookie";
import router from '@/router'
import iView from 'view-design'
import isPad from '@/utils/isPad.js'
let isPadEnv = isPad()
// import omljosn from "@/assets/js/omljosn.json";
const state = {
    expert: false, //false合作医生  true专家医生
    showApp: false, //页面显视状态
    userInfo: {},
    bz_list: [],
    token: [],
    indexSpin: { show: false },
    keyInfo: {
        frontChipId: '', //加密狗芯片唯一ID
        frontVerfiySign: '', //前端加密后的值
        sm2UserName: '', //用户名
    },
    connect: false, //用于判断是否连上密钥,
    isLoginActive: false, //是否登录状态下发送socket链接
    chiefComplaintTimeTypeList: [ //主诉时间类型(0天 1周 2月 3年)
        { value: 0, label: "天" },
        { value: 1, label: "周" },
        { value: 2, label: "月" },
        { value: 3, label: "年" }
    ],
    // 配置项
    // setInfo: {
    //     palmDevice: false, //手相设备 false:1.0 true: 2.0
    //     photoReminder1: false, //病历拍照提醒(待就诊)
    //     photoReminder2: false, //病历拍照提醒(就诊中)
    //     prescriptionNote: false, //是否打印处方笺
    //     feedbackSheet: false, //用户反馈单
    // },
    // 配置项
    userSystemConfig: {
        handDeviceVersion: "", //	手相设备版本（0 1.0版本 1 2.0版本）	integer(int32)	
        mrScanRemindVisit: "", //	病历扫描提醒（待就诊）	integer(int32)	
        mrScanRemindVisited: "", //	病历扫描提醒（就诊中）	integer(int32)	
        printPrescription: "", //	是否打印处方笺	integer(int32)	
        userFeedback: "", //	用户反馈单	integer(int32)
        selectEtiology: "", // 是否选病因 1 是 0 否
    },
    handData: {
        finger: [],
        nineHillsPlains: [],
        threeMainLine: [],
        palmName: "",
        palmType: "",
        picId: "",
    },
    getUserCloudSystem: [],
    version: "",
    showPadPage: false
}

const getters = {
    // 是否选病因 1 是 0 否
    selectEtiology: state => {
        return state.userInfo.isSkipMRA && state.userSystemConfig.selectEtiology == 0 ? 0 : 1;
    },
    palmDeviceSize: state => {
        return state.userSystemConfig.handDeviceVersion ? { width: 3840, height: 2160 } : { width: 3264, height: 2448 }
    },
    palmDevice: state => state.userSystemConfig.handDeviceVersion,
    version: state => state.version,
    getUserCloudSystem: state => state.getUserCloudSystem.map(i => i.value),
    handData: state => state.handData,
    showApp: state => state.showApp,
    connect: state => state.connect,
    chiefComplaintTimeTypeList: state => state.chiefComplaintTimeTypeList,
    chiefComplaintTimeTypeListName: state => state.chiefComplaintTimeTypeList.map(i => i.label),
    isLoginActive: state => state.isLoginActive,
    keyInfo: state => state.connect ? state.keyInfo : {},
    indexSpin: state => state.indexSpin,
    token: state => state.userInfo.token,
    userInfo: state => state.userInfo,
    isNoviceGuide: state => state.userInfo.isNoviceGuide == 1 ? true : false,
    timeDifference: state => state.userInfo.timeDifference, //服务器与本地的时间差
    expert: state => state.userInfo.userType == 1,
    onLogin: state => state.userInfo.doctorName,
    selfReport: state => true,
    userSystemConfig: state => state.userSystemConfig,
    userSystemConfig: state => state.userSystemConfig,
    showPadPage: state => state.showPadPage,
}

const mutations = {
    setVersion(state, res) {
        state.version = res;
    },
    setHandData(state, res) {
        state.handData = res;
    },
    setShowApp(state, res) {
        state.showApp = res;
    },
    clearHandData(state) {
        state.handData = {
            finger: [],
            nineHillsPlains: [],
            threeMainLine: [],
        };
    },
    setIsLoginActive(state, data) {
        state.isLoginActive = data;
    },
    connect(state, data) {
        state.connect = data;
    },
    setKeyInfo(state, data) {
        state.keyInfo = data;
        localStorage.setItem('keyInfo', JSON.stringify(data));
    },
    setisNoviceGuide(state, data) {
        state.userInfo.isNoviceGuide = data;
        localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
    },
    openSpin(state, text) {
        state.indexSpin = {
            show: true,
            text
        };
    },
    closeSpin() {
        state.indexSpin = {
            show: false,
            text: '',
        };
    },
    // 设置是否专家页面
    setExpert(state, data) {
        state.expert = data;
    },
    // 设置用户信息
    async setUserInfo(state, data) {
        state.showApp = false;
        state.userInfo = data;

        // 获取基础版本权限
        await this.dispatch("getUserCloudSystem");

        let keyInfo = localStorage.getItem('keyInfo');
        if (keyInfo) this.commit('setKeyInfo', JSON.parse(keyInfo))

        // 设置提醒过的处方ID
        let patientId = JSON.parse(localStorage.getItem('patientId'));
        if (patientId) this.commit('setPatientId', patientId);

        // 设置数据字典
        let dicts = JSON.parse(localStorage.getItem('dicts'));
        if (dicts) this.commit('setDicts2', dicts)

        let addDoctorRushBill = JSON.parse(localStorage.getItem('addDoctorRushBill'));
        if (addDoctorRushBill) this.commit('addDoctorRushBill2', addDoctorRushBill)

        let addMedicalRecordSymptom = JSON.parse(localStorage.getItem('addMedicalRecordSymptom'));
        if (addMedicalRecordSymptom) this.commit('addMedicalRecordSymptom', addMedicalRecordSymptom)

        let isAnalysisList = JSON.parse(localStorage.getItem('isAnalysisList'));
        if (isAnalysisList) this.commit('setIsAnalysisList', isAnalysisList)

        setTimeout(() => {
            // 启动socket
            this.commit('startSocket', data);
        }, 3000);
        // 获取药品列表
        await this.dispatch('getDrug');
        // 获取疾病信息
        await this.dispatch('getDiseaseInfos');
        await this.dispatch('getReloadOrderList');
        let cacheLog = localStorage.getItem('cacheLog');
        if (cacheLog) this.commit('setCacheLog', JSON.parse(cacheLog))
        state.showApp = true;
        this.dispatch("getWideUseList", "Disease_Type");

        // 获取配置
        this.dispatch("getUserSystemConfig");
    },

    // 设置用户信息
    setUserType(state, data) {
        state.userInfo.userType = data;
    },

    //云诊系统功能列表
    getUserCloudSystem(state, res) {
        state.getUserCloudSystem = res.result;
    },

    getUserSystemConfig(state, data) {
        if(!data) return;
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                data[key] = data[key] ? true : false;
            }
        }

        // 处理旧数据 (旧数据需要先保存到线上，然后删除本地缓存)
        let d = localStorage.getItem('setInfo')
        if (d) {
            d = JSON.parse(d)
            d.palmDevice && (data.handDeviceVersion = true)
            d.photoReminder1 && (data.mrScanRemindVisit = true)
            d.photoReminder2 && (data.mrScanRemindVisited = true)
            d.prescriptionNote && (data.printPrescription = true)
            d.feedbackSheet && (data.userFeedback = true)
            this.dispatch("saveUserSystemConfig", data).then(res=>{
                localStorage.removeItem('setInfo')
            })
        }

        state.userSystemConfig = data;
    },

    setUserSystemConfig(state, data) {
        state.userSystemConfig = data;
    },

    // 获取登录信息
    getUserLogin(state, res) {
        this.commit('closeSpin');
        this.commit('setIsLoginActive', true)
        res = res.result;
        //  清除就诊中及未就诊挂号(处方单)列表
        this.commit('clearPrescribeRegisterList');
        res.token = [res.token, res.token, res.crm_token];
        res.timeDifference = Date.parse(new Date(res.serverTime)) - Date.parse(new Date()); //保存本地和服务器的时差

        this.commit('setUserInfo', res)

        localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
        Cookies.set('userInfo', res.crm_token)
        this.commit('setExpert', state.doctorName);

        if (res.surplusDays == -1) {
            if (isPadEnv) {
                if (localStorage.getItem('notPadModeStatus') === 'false') {
                    localStorage.setItem('notPadModeStatus', false);
                    this.commit('setShowPadPage', false)
                    router.push('/diseaseEnter')
                } else {
                    localStorage.setItem('notPadModeStatus', true);
                    this.commit('setShowPadPage', true)
                    router.push('/index')
                }
            } else {
                router.push('/index')
            }
        } else {
            iView.Modal.warning({
                title: '提示',
                content: `使用天数还剩 ${res.surplusDays} 天`,
                onOk: () => {
                    router.push('/index')
                },
            });
        }
    },
    // 退出
    logOut(state) {
        Cookies.remove('userInfo')
        state.userInfo = {};
        // 清空缓存
        let exclude = ['_zhihu_version_', 'notPadModeStatus'];
        for (const key in localStorage) {
            if (localStorage.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    localStorage.removeItem(key);
                }
            }
        }
        for (const key in sessionStorage) {
            if (sessionStorage.hasOwnProperty(key)) {
                if (!exclude.includes(key)) {
                    sessionStorage.removeItem(key);
                }
            }
        }

        this.commit('closeSocket');
        router.push('/')
    },
    setShowPadPage(state, res) {
        state.showPadPage = res
    }
}

const actions = {
    // 获取用户参数配置
    getUserSystemConfig({ state, commit }, data) {
        commit('openSpin');
        return api2('getUserSystemConfig', data).then(res => {
            this.commit('closeSpin');
            commit('getUserSystemConfig', res.result || {})
        });
    },

    // 登录请求
    getUserLogin({ state, commit }, data) {
        commit('openSpin');
        Object.assign(data, state.keyInfo)
        return api2('getUserLogin', data).then(res => { commit('getUserLogin', res) });
    },

    //云诊系统功能列表
    getUserCloudSystem({ state, commit }, data) {
        data = {
            pageIndex: 1,
            pageSize: 1
        }
        return api2('getUserCloudSystem', data).then(res => { commit('getUserCloudSystem', res) });
    },
    // 保存配置
    saveUserSystemConfig({ state, commit }, data) {
        let obj = common.clone(data);  
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            obj[key] = obj[key] ? 1 : 0;
          }
        }
        return api2("saveUserSystemConfig", obj)
    },
}


export default {
    state,
    getters,
    actions,
    mutations
}