<template>
  <layer pagename="home" pageClass="homePage">

    <!-- 测试 -->
    <!-- <uconvas /> -->

    <div v-if="expert" class="qdan" :class="{ inabled_btn: visiting_zj.noVisitNum }">
      <button @click="addDoctorRushBill" class="qd_btn">接 诊</button>
    </div>
    <template v-else>
      <div class="bulletinBoard">
        <div class="b_b_left">
          <div class="u-tabs">
            <div @click="clickTabs(item)" class="b_title" :class="{ active: item.value == tabValue }" v-for="item in tabs"
              :key="item.value">
              {{ item.label }}
            </div>
          </div>
          <div class="from_mk">
            <template v-if="tabValue == 2">
              <udate dateLabel v-model="picker_Date" />
              <Select clearable v-model="searchType" placeholder="接诊归属">
                <Option v-for="item in option" :value="item.value" :key="item.value">{{ item.label }}</Option>
              </Select>
            </template>
            <Input class="keyWord" v-model="keyWord" @on-enter="search" placeholder="搜索患者（姓名/拼音首字母/手机号）"></Input>
            <Button @click="search" type="primary">搜索</Button>


          </div>
          <div class="table_mk">
            <Table :columns="column" :data="list">
              <template slot-scope="{ row }" slot="patientName">
                <div @click="clickItem(row)" class="patient_name">
                  <Icon v-if="row.sex == 'M'" type="md-male" />
                  <Icon v-else type="md-female" />
                  <span>{{ row.patientName }}</span>
                </div>
              </template>
              <template slot-scope="{ row }" slot="hospitalName">
                <div class="hospital_k">
                  <div class="name">{{ row.hospitalName }}</div>
                  <div class="btn_group" :class="{
                    not_add: !row.isAddPrescription,
                    isEdit: tabValue == 1 && row.isPaid != 2,
                  }">
                    <div @click="clickBtn(item.id, row)" class="btn_item" :class="[item.className]"
                      v-for="(item, index) in btn" :key="index">
                      <div class="icon">
                        <Icon :type="item.icon" />
                      </div>
                      <div class="label">{{ item.label }}</div>
                    </div>
                  </div>
                </div>
              </template>
              <template slot-scope="{ row }" slot="isFirstVisit">
                <Icon v-if="row.isFirstVisit" class="green" type="md-checkmark" />
              </template>
            </Table>
          </div>
          <div class="footer">
            <Page :total="total" @on-change="pageChange" :page-size="pageSize" :current="pageIndex" size="small" />
          </div>
        </div>
        <div v-if="isShow" class="b_b_right">
          <div class="b_title">感动时刻</div>
          <Carousel v-model="gdsk" loop>
            <CarouselItem v-for="item in 3" :key="item">
              <div class="ca_content">
                <div class="pic">
                  <Carousel arrow="never" autoplay class="pic_carousel" loop>
                    <CarouselItem v-for="item in 3" :key="item">
                      <img src="@/assets/images/t1.jpg" alt />
                    </CarouselItem>
                  </Carousel>
                </div>
                <div class="ca_info">
                  <Icon type="ios-quote" />
                  <div class="ca_text">
                    宋阿姨肩痛难忍来看病，我们没给她开药，按了几个穴位后瞬间轻松舒服了好多，她大为震撼。特意买来水果感谢我们。宋阿姨肩痛难忍来看病，我们没给她开药，按了几个穴位后瞬间轻松舒服了好多，她大为震...
                  </div>
                  <div class="more">
                    <span>更多</span>
                  </div>
                  <div class="ca_footer">——徐氏中医德兴医馆</div>
                </div>
              </div>
            </CarouselItem>
          </Carousel>
        </div>
        <uArchives ref="archives" :info="activeItem" type="btn"></uArchives>
      </div>
      <medicalRecord v-model="medicalRecordOpen" :info="info" />
    </template>
  </layer>
</template>

<script>
import uconvas from '@/com/etiologicalAnalysis/com/uconvas2.vue';
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    uconvas
  },
  data() {
    return {
      activeItem: {},
      searchType: 2,
      tabValue: 1,
      tabs: [
        {
          label: "今日接诊",
          value: 1,
          active: true,
        },
        {
          label: "全部接诊",
          value: 2,
          active: false,
        },
      ],
      isShow: false,
      medicalRecordOpen: false,
      info: {},
      option: [
        { value: 1, label: "我接诊的" },
        { value: 2, label: "本医馆" },
        { value: 3, label: "全部医馆" },
      ],
      list: [],
      total: 0,
      gdsk: 0,
      pic_carousel: 0,
      keyWord: "",
      picker_Date: [],
      pageIndex: 1,
      pageSize: 10,
      btn: [
        {
          id: 1,
          label: "病历扫描",
          icon: "md-camera",
          className: "blsm",
        },
        {
          id: 2,
          label: "查看详情",
          icon: "ios-print-outline",
          className: "cf",
        },
        {
          id: 3,
          label: "添加处方",
          icon: "ios-document-outline",
          className: "tjcf",
        },
        {
          id: 4,
          label: "编辑处方",
          icon: "ios-hammer-outline",
          className: "bjcf",
        },
      ],
      ydwinShow: false,
      u_popup: "",
      showpopup: false,
    };
  },
  computed: {
    ...mapGetters([
      "prescribeRegisterList", // 就诊中及未就诊挂号(处方单)
      "expert",
      "visiting_zj",
      "userInfo",
      "isNoviceGuide",
      "introStart",
      "drug",
    ]),
    column() {
      let list = [
        {
          title: "序号",
          type: "index",
          align: "center",
          width: 70,
        },
        {
          title: "患者",
          key: "patientName",
          slot: "patientName",
          width: 130,
        },
        {
          title: "手机号",
          key: "cellphoneNo",
          align: "center",
          width: 150,
        },
        {
          title: "年龄",
          key: "patientAge",
          align: "center",
          width: 100,
        },
        {
          title: "就诊时间",
          key: "visitDate",
          align: "center",
          width: 200,
        },
        {
          title: "主诉",
          key: "diagnosticName1",
          align: "center",
          width: 200,
        },
        {
          title: "诊断",
          key: "diagnosticName2",
          align: "center",
          width: 120,
        },
        {
          title: "医馆",
          key: "hospitalName",
          slot: "hospitalName",
          align: "center",
          width: 240,
        },
        {
          title: "医生",
          key: "doctorName",
          align: "center",
          width: 200,
        },
      ];
      if (this.tabValue == 1) {
        list.push({
          title: "新患者",
          key: "isFirstVisit",
          align: "center",
          slot: "isFirstVisit",
          minWidth: 80,
        });
      } else {
        list.push({
          title: "处方号",
          key: "prescriptionNo",
          align: "center",
          minWidth: 80,
        });
      }
      return list;
    },
  },
  methods: {
    ...mapMutations(["setRegForm"]),
    clickItem(item) {
      this.activeItem = item;
      this.$refs.archives.show();
    },
    clickTabs(item) {
      this.tabValue = item.value;
      this.pageIndex = 1;
      this.getData();
    },
    pageChange(index) {
      this.pageIndex = index;
      this.getData();
    },
    clickBtn(BtnId, item) {
      switch (BtnId) {
        case 1:
          this.showCameraFun(item);
          break;
        case 2:
          this.searchData(item);
          break;
        case 3:
          this.addPrescription(item);
          break;
        case 4:
          this.editPrescription(item);
          break;
      }
    },

    // 编辑处方
    editPrescription(item) {
      let obj = {
        prescriptionNo: item.prescriptionNo,
        payStatus: item.payStatus || 0,
      };
      this.$store.commit("openSpin");
      this.api2("getZHMedicalRecordNewList", obj).then(res => {
        this.$store.commit("closeSpin");

        let prescriptionHead = res.result.prescriptionHead[0] || {};
        // 保存处方数据
        let list = res.result.frontPrescription;
        let prescription = {
          Quantity: 0,
          drugList: [],
        }
        prescription.Quantity = prescriptionHead.quantity;
        list.forEach((i) => {
          let drugItem = this.drug.find((j) => j.Drug_Code == i.drugCode);
          prescription.drugList.push({
            drugTaboo: [],
            error: false,
            flevel: 0,
            active: false,
            Drug_Code: i.drugCode,
            Drug_Id: i.drugId,
            Drug_Name: i.drugName,
            Sales_Price: i.salesPrice,
            specifications: drugItem
              ? drugItem.specifications
              : i.specifications,
            drugDecoctPattern: drugItem
              ? drugItem.drugDecoctPattern
              : i.drugDecoctPattern,
            drugTakePattern: drugItem
              ? drugItem.drugTakePattern
              : i.drugTakePattern,
            StockQty: drugItem ? drugItem.StockQty : 0, //库存
            UnitName: drugItem ? drugItem.UnitName : i.unit,
            Amount: i.amount,
            Quantity: i.quantity,
            discountRate: i.discountRate,
            Drug_Type: drugItem ? drugItem.Drug_Type : "",
            delBtn: false, //删除按钮
          });
        });

        this.$store.commit("savePrescription", {
          key: item.registerCode,
          prescription: prescription,
        });

        this.updateMedicalRecordSymptomStatus(item);
      })
    },
    updateMedicalRecordSymptomStatus(item) {
      this.$store.commit("openSpin");
      this.api2("updateMedicalRecordSymptomStatus", {
        symptomNo: item.symptomNo,
      }).then(async (res) => {
        this.$store.commit("closeSpin");
        await this.$store.dispatch("getReloadOrderList");
        this.$router.push("/prescription/" + item.registerCode);
      });
    },
    // 添加处方
    addPrescription(item) {
      let list = this.prescribeRegisterList.NoVisit.details;
      if (list.length) {
        let patient = list.find((i) => i.patientCode == item.patientCode);
        if (patient) {
          this.$router.push("/condition/" + patient.registerCode);
          return;
        }
      }

      this.$store.commit("openSpin");
      this.api2("addPrescription", { registerCode: item.registerCode }).then(
        async (res) => {
          // 保存id 用于自动引用历史症状
          let ids = [];
          let d = localStorage.getItem("isAddPrescription");
          if (d) ids = JSON.parse(d);
          ids.push(item.registerCode);
          localStorage.setItem("isAddPrescription", JSON.stringify(ids));

          this.$store.commit("setRegister_dq", {
            patientName: item.patientName,
            cellphoneNo: item.cellphoneNo,
          });
          await this.$store.dispatch("getReloadOrderList");
          this.$store.commit("closeSpin");
        }
      );
    },
    // 打开处方弹框
    searchData(item) {
      //弹窗打开
      this.medicalRecordOpen = true;
      // 处方ID
      this.info = {
        patientCode: item.patientCode,
        prescriptionNo: item.prescriptionNo,
        payStatus: item.typeId,

        isSettlement: item.typeId,
        visitDate: item.visitDate,
      };
    },
    addDoctorRushBill() {
      if (this.visiting_zj.noVisitNum) {
        this.$store.dispatch("addDoctorRushBill");
      } else {
        this.$Message.info("无单可接");
      }
    },
    showCameraFun(item) {
      let info = {
        patientCode: item.patientCode,
        registerCode: item.registerCode,
        registerDateTime: item.visitDate,
        patientName: item.patientName,
        sex: item.fSex,
        patientAge: item.patientAge,
        cellphoneNo: item.cellphoneNo,
      };
      this.$store.commit("setCameraInfo2", {
        info,
        typeId: 0,
        showWin: true,
      });
    },
    search() {
      this.pageIndex = 1;
      this.getData();
    },
    getData() {
      this.list = [];
      this.total = 0;

      this.$store.commit("openSpin");
      let obj = {
        keyWord: this.keyWord,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };

      if (this.tabValue == 1) {
        let date = this.common.parseTime(new Date(), "{y}-{m}-{d}");
        obj.startDate = date;
        obj.endDate = date;
        obj.searchType = 4; //    4云诊首页查询
      } else {
        if (this.picker_Date.length) {
          obj.startDate = this.picker_Date[0]
            ? this.common.parseTime(this.picker_Date[0], "{y}-{m}-{d}")
            : "";
          obj.endDate = this.picker_Date[1]
            ? this.common.parseTime(this.picker_Date[1], "{y}-{m}-{d}")
            : "";
        }
        obj.searchType = this.searchType;
      }

      this.api2("getPatientPrescriptionHistoryList", obj).then(
        (res) => {
          this.$store.commit("closeSpin");
          this.list = res.result.map((i) => {
            i.patientName = i.patientName;

            // 测试
            i.isAddPrescription = 1;
            
            return i;
          });
          this.total = res.total;
        }
      )
    },
  },
  mounted() {
    $(".qd_btn").focus();
  },
  created() {
    this.picker_Date = this.common.getNowMonth();

    this.getData();
    if (!this.expert) this.ydwinShow = !this.isNoviceGuide;

    if (localStorage.getItem("isFromLoginPage")) {
      this.showpopup = true;
      localStorage.removeItem("isFromLoginPage");

      setTimeout(() => {
        this.u_popup = "move_top";
      }, 3000);
    }
  },
};
</script>
<style scoped>
@import url("./style.css");
</style>

