<!-- 症状选择组件收集页 -->
<template>
  <div class="select_zz">
    <div class="con_dh" :md="6" :sm="24">
    <div class="u_tabs">
      <div @click="clickTabs(1)" class="u_tabs_item" :class="{ active: activeTabName == 1 }">看名选症</div>
      <div v-if="!filterDtId" @click="clickTabs(2)" class="u_tabs_item" :class="{ active: activeTabName == 2 }">看图选症
      </div>
    </div>
    <div v-show="activeTabName == 1" class="bz_xl">
      <Select prefix="ios-search" class="bz_value" ref="bz_value" v-model="bz_value" @on-query-change="changeKye" @on-change="changeValue"
        filterable>
        <Option v-for="item in symptomList2" :value="item.value" :label="item.label" :key="item.value">{{ item.text }}
        </Option>
      </Select>
    </div>
    <div v-show="activeTabName == 1 && !filterDtId" class="myScrollspy">
      <ul class="nav">
        <li v-for="(bz, index) in u_bz_list" :key="index">
          <a @click.prevent="click_scrool(bz.dtId)" :href="'#id_' + bz.dtId">{{ bz.diagnosticName }}</a>
        </li>
      </ul>
    </div>
    <div v-show="activeTabName == 2" class="myScrollspy2">
      <ul class="nav">
        <br />
        <li class="active">
          <a>皮肤病</a>
        </li>
      </ul>
    </div>
    </div>

    <div id="step1" class="bz_list" :md="18" :sm="24">
    <div v-show="activeTabName == 1" class="scroll_list" data-spy="scroll" data-target=".myScrollspy" data-offset="0"
      style="overflow-y:auto; overflow-x: hidden; position: relative;">
      <div class="section" v-for="(bz, index) in u_bz_list" :key="index">
        <div :id="'id_' + bz.dtId" class="scroll_dw"></div>
        <div v-for="(bz_item, b_index) in bz.symptomSubTypeList" class="item_bz"
          :class="{ disabled: bz_item.exclude.id }" :key="b_index">
          <div :id="'id_' + bz_item.dtId" class="scroll_dw"></div>

          <div class="bz_title">
            <div @click="jump(bz_item.exclude.id, 'group')" v-if="bz_item.exclude.id" class="exclude_ts">
              <div class="nr">与大类目"{{ bz_item.exclude.name }}"互斥</div>
            </div>
            {{ bz_item.diagnosticName }}
            <span v-if="bz_item.isnecessary">(必选)</span>
          </div>
          <div class="bz_xx">
            <span v-for="(fxk, f_index) in bz_item.symptomList.filter(i => i.isFrequently)" :key="f_index"
              class="symptomItem" :class="'id_' + fxk.dsId">
              <div @click="jump(fxk.exclude.id)" v-if="fxk.exclude.id" class="exclude_ts">
                <div class="nr">与症状"{{ fxk.exclude.name }}"互斥</div>
              </div>

              <symptomDropdown :symptomInfo="fxk"  @clickMenu="clickMenu" >
                <Checkbox :id="bz_item.isnecessary && !f_index ? 'hint_' + index + '_' + b_index : ''"
                  :class="bz_item.isnecessary && !f_index ? 'hint_heidicon' : ''" :disabled="!!fxk.exclude.id"
                  @on-change="clickItem(fxk, bz_item.isnecessary && !f_index ? 'hint_' + index + '_' + b_index : '')"
                  :name="'id_' + fxk.dsId" v-model="fxk.Other">
                  {{ fxk.dsName }}
                  <img class="isnew" v-if="fxk.isNew" src="@/assets/images/new3.png" />
                  <Icon v-if="fxk.picUrl || fxk.descs" class="help_icon" @click.prevent="openDetail(fxk)"
                    type="md-help-circle" />
                </Checkbox>
              </symptomDropdown>

            </span>
            <span v-if="bz_item.symptomList.filter(i => !i.isFrequently).length">
              <label @click="clickMear" class="mear">更多...</label>
            </span>
            <p style="display: none;">
              <span v-for="(fxk, f_index) in bz_item.symptomList.filter(i => !i.isFrequently)" :key="f_index"
                class="symptomItem" :class="'id_' + fxk.dsId">
                <div @click="jump(fxk.exclude.id)" v-if="fxk.exclude.id" class="exclude_ts">
                  <div class="nr">与症状"{{ fxk.exclude.name }}"互斥</div>
                </div>
                <symptomDropdown :symptomInfo="fxk"  @clickMenu="clickMenu" >
                  <Checkbox :disabled="!!fxk.exclude.id" @on-change="clickItem(fxk)" :name="'id_' + fxk.dsId"
                    v-model="fxk.Other">
                    {{ fxk.dsName }}
                    <img class="isnew" v-if="fxk.isNew" src="@/assets/images/new3.png" />
                    <Icon v-if="fxk.picUrl || fxk.descs" class="help_icon" @click.prevent="openDetail(fxk)"
                      type="md-help-circle" />
                  </Checkbox>
                </symptomDropdown>

              </span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- 皮肤病选项 -->
    <div v-show="activeTabName == 2" class="scroll_list" data-spy="scroll" data-target=".myScrollspy" data-offset="0"
      style="overflow-y:auto; overflow-x: hidden; position: relative;">
      <div class="section" v-for="(bz, index) in u_bz_list" :key="index">
        <div :id="'id_' + bz.dtId" class="scroll_dw"></div>
        <div v-for="(bz_item, b_index) in bz.symptomSubTypeList.filter(i => i.dtId == 20030)" class="item_bz"
          :class="{ disabled: bz_item.exclude.id }" :key="b_index">
          <div :id="'id_' + bz_item.dtId" class="scroll_dw"></div>
          <div class="bz_title">
            <div @click="jump(bz_item.exclude.id, 'group')" v-if="bz_item.exclude.id" class="exclude_ts">
              <div class="nr">与大类目"{{ bz_item.exclude.name }}"互斥</div>
            </div>
            {{ bz_item.diagnosticName }}
            <span v-if="bz_item.isnecessary">(必选)</span>
          </div>
          <div class="bz_xx bz_xx_img_k">
            <div class="symptomItem_k" v-for="(fxk, f_index) in bz_item.symptomList.filter(i => i.picUrl)"
              :key="f_index">
              <span class="symptomItem" :class="'id_' + fxk.dsId" :id="'id_' + fxk.dsId">
                <div @click="jump(fxk.exclude.id)" v-if="fxk.exclude.id" class="exclude_ts">
                  <div class="nr">与症状"{{ fxk.exclude.name }}"互斥</div>
                </div>
                <div class="img_k" @click="clickPic('id_' + fxk.dsId)">
                  <img v-if="fxk.picUrl" :src="picroot(fxk.picUrl, 'symptom')" />
                  <img v-else src="@/assets/images/empty.jpg" />
                </div>
                <Checkbox :id="bz_item.isnecessary && !f_index ? 'hint_' + index + '_' + b_index : ''"
                  :class="bz_item.isnecessary && !f_index ? 'hint_heidicon' : ''" :disabled="!!fxk.exclude.id"
                  @on-change="clickItem(fxk, bz_item.isnecessary && !f_index ? 'hint_' + index + '_' + b_index : '')"
                  :name="'id_' + fxk.dsId" v-model="fxk.Other">
                  {{ fxk.dsName }}
                  <img class="isnew" v-if="fxk.isNew" src="@/assets/images/new3.png" />
                  <Icon v-if="fxk.picUrl || fxk.descs" class="help_icon" @click.prevent="openDetail(fxk)"
                    type="md-help-circle" />
                </Checkbox>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span id="activeitem" style="color:red;"></span>
    </div>
    <Modal footer-hide v-model="detailWin" title="症状说明">
      <div class="s_select_tip">
        <div v-html="detailData.descs"></div>
        <div v-if="detailData.picUrl" class="img_k_2" id="deetailData_pic">
          <div class="title3">图例：</div>
          <img @click="clickPic('deetailData_pic')" :src="picroot(detailData.picUrl, 'symptom')" />
        </div>
      </div>
    </Modal>
    <utips v-model="showTip" />
  </div>
</template>
<script>
import utips from '@/com/utips.vue'
import viewer from "viewerjs";
import { mapGetters } from "vuex";
import u_common from "@/common.js";
export default {
  props: {
    sexAndAge: {
      type: Object,
      default: () => {
        return { age: 15, sex: 2 };
      }
    },
    quote: {
      //引用
      type: Object,
      default: () => {
        return {
          ids: [],
          type: false //true为标红症状
        };
      }
    },
    sort: {
      type: Boolean,
      default: false
    },
    filterDtId: { //过滤的dtId
      type: String,
      default: '',
    }
  },
  data() {
    return {
      showTip: false,
      dsId: 30417, //初诊

      detailWin: false,
      detailData: {},
      activeTabName: 1,
      wwin: false,
      dsDetail: {},

      subTypeList: [], //症状小类列表
      symptomList: [], //症状列表
      symptomList2: [], //症状列表
      isnecessaryList: [], //必选类列表
      selectList: [],
      u_bz_list: [],
      bz_value: "",
      kg: true,
      maxIndex: 1
    };
  },
  components: {
    utips,
  },
  computed: {
    ...mapGetters(["diagnosticList", "contradictDiagnosticList", "visit_bz"])
  },
  watch: {
    diagnosticList(value) {
      this.sx_diagnosticList();
      this.setSymptomList();
    },
    sexAndAge(value) {
      $(".scroll_list").animate({ scrollTop: 0 }, 200);
      this.sx_diagnosticList();
    },
    visit_bz(value) {
      this.sx_diagnosticList();
    },
    symptomList: {
      handler: function (val, oldVal) {
        let arr = val.filter(i => i.Other);
        if (this.sort) {
          function compare(prop) {
            return function (a, b) {
              return a[prop] - b[prop];
            };
          }
          arr.sort(compare("index"));
        }
        this.$emit("input", arr);
      },
      deep: true
    },
    quote: {
      handler: function (val) {
        this.setSymptomList();
      },
      deep: true
    }
  },
  methods: {
    contentFormat(item) {
      let pic = item.picUrl
        ? `图例:<br/><img src="${this.picroot(item.picUrl, "symptom")}" />`
        : "";
      return `${item.descs}<br/>${pic}`;
    },
    clickTabs(nb) {
      this.activeTabName = nb;
    },
    clickPic(ref) {
      if (this.picView) this.picView.destroy();
      this.picView = new viewer($("#" + ref)[0]);
    },
    openDetail(item) {
      this.detailData = item;
      this.detailWin = true;
    },
    setSymptomList() {
      this.u_bz_list.forEach(j => {
        j.symptomSubTypeList.forEach(k => {
          if (!k.exclude.id) {
            k.symptomList.forEach(i => {
              if (!i.exclude.id && this.quote.ids.includes(i.dsId + "")) {
                if (this.quote.type) i.active = true;
                i.Other = true;
                i.index = this.maxIndex;
                this.maxIndex++;
                this.clickSymptoms();
              }
            });
          }
        });
      });
    },

    removeSymptom(ids) {
      this.symptomList.forEach(i => {
        if (ids.includes(i.dsId + "")) {
          if (this.quote.type) i.active = true;
          i.Other = false;
        }
      });
      setTimeout(() => {
        this.clickSymptoms();
      }, 300);
    },

    clickMenu(item){
      this.$emit("clickMenu", item)
    },

    clickItem(item, id) {
      item.index = this.maxIndex;
      this.maxIndex++;
      this.clickSymptoms();
      if(item.Other && item.dsId == this.dsId){
        this.showTip = true;
      }
      this.$emit("clickItem", item)
    },
    clickSymptoms() {
      let aItems = this.symptomList.filter(i => i.Other);
      let parentIds = aItems.map(i => i.parentId);
      var subType = this.contradictDiagnosticList.SubType.filter(
        i => parentIds.indexOf(i.dtId) != -1
      );
      this.subTypeList.forEach(i => {
        i.exclude = {
          id: "",
          name: ""
        };
      });
      subType.forEach(i => {
        let item = this.subTypeList.find(j => j.dtId == i.dtId2);
        if (item) {
          item.exclude = {
            id: i.dtId,
            name: i.dtName
          };
        }
      });

      let dsIds = aItems.map(i => i.dsId);
      this.symptomList.forEach(i => {
        i.exclude = {
          id: "",
          name: ""
        };
      });

      var symptom = this.contradictDiagnosticList.Symptom.filter(
        i => dsIds.indexOf(i.dsId) != -1
      );
      symptom.forEach(i => {
        let item = this.symptomList.find(j => j.dsId == i.dsId2);
        if (item) {
          item.exclude = {
            id: i.dsId,
            name: i.dsName
          };
        }
      });
    },
    jump(id, type) {
      if (type) {
        this.click_scrool(id);
      } else {
        this.goto(id);
      }
    },
    clickMear(e) {
      var node = $(e.target)
        .parent()
        .next();
      node.slideToggle(200);
    },
    //刷新症状列表
    sx_diagnosticList() {
      this.maxIndex = 1;
      let list = this.diagnosticList;
      if (this.filterDtId) list = list.filter(j => {
        return j.symptomSubTypeList.find(i => i.dtId == this.filterDtId)
      });

      var newList = u_common.clone(list);
      newList.forEach(i => {
        i.symptomSubTypeList.forEach(j => {
          if (!("exclude" in j)) {
            j.exclude = {
              id: "",
              name: ""
            };
          }
          j.symptomList.forEach(k => {
            if (!("exclude" in k))
              k.exclude = {
                id: "",
                name: ""
              };
            k.Other = false;
            k.active = false;
            k.index = 1000;
            k.parentId = j.dtId;
            k.parentName = j.diagnosticName;
          });
        });
      });

      newList = this.common.diagnosticListFilter(
        newList,
        this.sexAndAge.age,
        this.sexAndAge.sex
      );

      this.u_bz_list = u_common.clone(newList);
      this.$store.commit("setU_bz_list", this.u_bz_list);
      this.subTypeList = [];
      this.symptomList = [];
      this.symptomList2 = [];

      this.u_bz_list.forEach(i => {
        i.symptomSubTypeList.forEach(j => {
          this.subTypeList.push(j);
          j.symptomList.forEach(k => {
            this.symptomList.push(k);
            this.symptomList2.push({
              label: k.dsName + "_" + k.py,
              value: k.dsName + "_" + k.py + "_" + k.dsId,
              text: k.dsName
            });
          });
        });
      });

      this.isnecessaryList = this.subTypeList.filter(i => i.isnecessary);
    },

    // 验证必选择项
    must() {
      var sList = this.symptomList.filter(i => i.Other);
      var pass = true;
      for (let i = 0; i < this.isnecessaryList.length; i++) {
        const element = this.isnecessaryList[i];
        if (
          !element.exclude.id &&
          !sList.some(j => j.parentId == element.dtId)
        ) {
          var pass = false;
          this.click_scrool(element.dtId);
          this.$Message.warning(`【${element.diagnosticName}】组必选!`);
          break;
        }
      }
      return pass;
    },
    // 点击滚动
    click_scrool(id) {
      var offset = $("#id_" + id).position();
      var h = $(".scroll_list").scrollTop() + offset.top;
      $(".scroll_list").animate({ scrollTop: h }, 200);
      $(".con_dh").removeClass("con_dh_a");
    },
    clearValue() {
      this.bz_value = "";
      this.$refs.bz_value.reset();
      this.focus();
    },
    focus() {
      $(this.$el).find(".bz_value input").focus();
    },
    changeKye(e) {
      if (e && this.$refs.bz_value.flatOptions.length) {
        this.$refs.bz_value.focusIndex = 0;
      }
    },
    // 搜索值改变时
    changeValue(value) {
      if (!value) return;
      this.clearValue();
      setTimeout(() => {
        this.clearValue();
      }, 200);
      var bz_id = value.match(/\d+$/)[0];
      this.symptomList.forEach(y => {
        if (y.dsId == bz_id) {
          if (y.exclude.name) {
            this.$Message.warning(`与【${y.exclude.name}】互斥,不可选择!`);
          } else {
            y.Other = true;
            y.index = this.maxIndex;
            this.maxIndex++;
            this.$emit("clickItem", y)
          }
        }
      });
      this.goto(bz_id);
      this.clickSymptoms();
    },
    // 跳转到指定位置
    goto(id) {
      var offset = $(".id_" + id).position();
      var h = $(".scroll_list").scrollTop() + offset.top - 30;
      $(".scroll_list").animate({ scrollTop: h }, 200);
    }
  },
  created() { },
  async mounted() {
    await this.$store.dispatch("getDiagnosticList");
    this.sx_diagnosticList();

    // 搜索下拉框隐藏或显视
    $(".bz_value .ivu-input").keyup(function (e) {
      setTimeout(function () {
        if (e.target.value) {
          $(".bz_value .ivu-auto-complete").css({ visibility: "visible" });
        } else {
          $(".bz_value .ivu-auto-complete").css({ visibility: "hidden" });
        }
      }, 100);
    });

    //滚动高亮显视
    setTimeout(() => {
      var liArr = $(".myScrollspy .nav li");
      $(liArr[0]).addClass("active");
      var secArr = $(".scroll_list .section");
      $(".scroll_list").bind("scroll", function (e) {
        for (var i = 0; i < secArr.length; i++) {
          if ($(secArr[i]).position().top >= 0) {
            liArr.removeClass("active");
            $(liArr[i]).addClass("active");
            break;
          }
        }
      });
    }, 1000);

    this.setSymptomList();

  }
};
</script>
<style lang="scss">
.select_zz {
  width: 100%;
}

.condition .bz_list {
  overflow: hidden;
  padding: 15px;
}

.studywin3 {
  position: absolute;
  top: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  /* background: rgba(33, 33, 33, 0.88); */
  background: #ffffff;
  transition: all 0.5s;
}

.studywin3.show {
  right: 0;
}

.studywin3 .s_header {
  color: #fff;
  background: #853a2f;
  display: flex;
  padding: 0 15px;
  line-height: 46px;
  justify-content: space-between;
}

.studywin3 .s_header .s_title {
  font-size: 20px;
  font-weight: bold;
  padding-left: 10px;
}

.studywin3 .s_body {
  padding: 20px 36px;
  font-size: 15px;
}

.studywin3 .s_body .point {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 5px;
}

.studywin3 .s_close {
  cursor: pointer;
}

.studywin3 .s_close i {
  font-size: 24px;
}

.studywin3 .item {
  padding-bottom: 15px;
}

.studywin3 .item.xj img {
  width: 300px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.select_zz .u_tabs {
  display: flex;
  text-align: center;
  line-height: 36px;
  height: 36px;
  padding: 0 15px;
}

.select_zz .u_tabs .u_tabs_item {
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  color: #999;
}

.select_zz .u_tabs .u_tabs_item.active {
  background: none;
  font-weight: bold;
  border-color: #853a2f;
  color: #333;
}
.select_zz{
  display: flex;
  .ivu-select-selection{
    border-radius: 15px;
    background: #f9f9fb;
    border-color: #f9f9fb;
    .ivu-select-prefix{
      padding-left: 10px;
    }
  }
  .con_dh{
    width: 220px;
    flex-shrink: 0;
  }
  .bz_list{
    width: 100%;
    background: #f5f5f5;
    .scroll_list{
      border-radius: 10px;
    }
  }
  .ivu-select-dropdown{
    top: 35px !important;
  }
}
</style>
