<template>
  <span class="symptom-dropdown">
    <Badge :text="badgeText">
      <slot></slot>
    </Badge>
    <div class="menu" v-if="isShowDropdown">
      <div class="menu-item"></div>
      <div class="menu-item" v-if="isShowSymptomChanges" @click="clickMenu(2)">
        {{ symptomInfo.symptomChanges == 2 ? "移除" : "" }}减轻
      </div>
      <div class="menu-item" v-if="isShowSymptomChanges" @click="clickMenu(1)">
        {{ symptomInfo.symptomChanges == 1 ? "移除" : "" }}加重
      </div>
      <div
        class="menu-item"
        v-if="selectEtiology"
        @click="clickMenu(3)"
        :divided="isShowSymptomChanges ? true : false"
      >
        选病因
      </div>
    </div>
    <!-- <Dropdown @on-click="clickMenu" v-if="isShowDropdown">
      <DropdownMenu slot="list">
        <DropdownItem v-if="isShowSymptomChanges" name="2"
          >{{ symptomInfo.symptomChanges == 2 ? "移除" : "" }}减轻</DropdownItem
        >
        <DropdownItem v-if="isShowSymptomChanges" name="1"
          >{{ symptomInfo.symptomChanges == 1 ? "移除" : "" }}加重</DropdownItem
        >
        <DropdownItem
          v-if="selectEtiology"
          name="3"
          :divided="isShowSymptomChanges ? true : false"
          >选病因</DropdownItem
        >
      </DropdownMenu>
    </Dropdown> -->
    <!-- <slot v-else></slot> -->
  </span>

  <!-- 
    <span class="symptom-dropdown">
    <Dropdown @on-click="clickMenu" v-if="isShowDropdown">
      <Badge :text="badgeText">
        <slot></slot>
      </Badge>
      <DropdownMenu slot="list">
        <DropdownItem v-if="isShowSymptomChanges" name="2"
          >{{ symptomInfo.symptomChanges == 2 ? "移除" : "" }}减轻</DropdownItem
        >
        <DropdownItem v-if="isShowSymptomChanges" name="1"
          >{{ symptomInfo.symptomChanges == 1 ? "移除" : "" }}加重</DropdownItem
        >
        <DropdownItem
          v-if="selectEtiology"
          name="3"
          :divided="isShowSymptomChanges ? true : false"
          >选病因</DropdownItem
        >
      </DropdownMenu>
    </Dropdown>
    <slot v-else></slot>
  </span>
   -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["patientLastSymptomIds", "selectEtiology"]),
    isShowSymptomChanges() {
      // 暂时关闭 下个版本发布
      return false;
      return this.patientLastSymptomIds.includes(this.symptomInfo.dsId + "");
    },
    isShowDropdown() {
      return (
        this.symptomInfo.Other &&
        (this.selectEtiology || this.isShowSymptomChanges)
      );
    },
    badgeText() {
      switch (this.symptomInfo.symptomChanges) {
        case "1":
          return "加重";
        case "2":
          return "减轻";
        default:
          return "";
          break;
      }
    },
  },
  props: {
    symptomInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  methods: {
    clickMenu(value) {
      switch (value) {
        case 3:
          this.$emit("clickMenu", this.symptomInfo, value);
          break;
        case 1:
          this.symptomInfo.symptomChanges =
            this.symptomInfo.symptomChanges == 1 ? 0 : value;
          break;
        case 2:
          this.symptomInfo.symptomChanges =
            this.symptomInfo.symptomChanges == 2 ? 0 : value;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.symptom-dropdown {
  position: relative;
  display: inline-block;
  &:hover {
    .menu {
      display: inline-block;
    }
  }
  .menu {
    display: none;
    position: absolute;
    top: 35px;
    left: 0;
    width: 100px;
    z-index: 1;
    background: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
    padding: 5px 0;
    border-radius: 5px;
    .menu-item {
      line-height: 30px;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        background: #f5f5f5;
      }
    }
  }
}
</style>