<template>
  <!-- 病历列表(就诊中和待就诊页用) -->
  <div class="medical_record_list">
    <div class="medical_record_list_body">
      <div class="m_r_l_item" v-for="(item, index) in list" :key="index">
        <div class="hander">
          <span>
            {{ item.visitDate }}
          </span>
          <span> {{ item.hospitalName }} {{ item.doctorName }} </span>
        </div>

        <div class="item">
          <div class="label">主诉</div>
          <div class="value">
            {{ item.purposeOfConsultationName }}
            <span class="other">{{ chiefComplaint(item) }}</span>
          </div>
        </div>
        <div class="item">
          <div class="label">诊断</div>
          <div class="value">
            {{ item.diseaseTypeName }}
            <span class="other">{{ Disease_Type(item.diseaseTypeId) }}</span>
          </div>
        </div>
        <div class="item" v-if="item.menstruationLastTime">
          <div class="label">末次月经</div>
          <div class="value">{{ item.menstruationLastTime }}</div>
        </div>
        <div class="item">
          <div class="label">症状</div>
        </div>
        <div class="item">
          <div class="value">
            <Badge v-for="ds in item.dsVOList" :key="ds.dsId" :text="ds.isAddDs ? '增' : ''">
              <span class="symptom_name">{{ ds.dsName }}</span>
            </Badge>
            <i class="line" v-if="item.removeDsVOList && item.removeDsVOList.length"></i>
            <Badge v-for="ds in item.removeDsVOList" :key="ds.dsId" class="symptom_remove" text="减">
              <span class="symptom_name">{{ ds.dsName }}</span>
            </Badge>
          </div>
        </div>

        <div class="item">
          <div class="label">患者自述</div>
          <div class="value">{{ item.chiefComplaint }}</div>
        </div>
        <template v-if="item.diagnosticDrugsVOList">
          <div class="item">
            <div class="label">处方</div>
          </div>
          <div class="item cf">
            <div class="value">
              <drugs-format :list="item.diagnosticDrugsVOList" />
            </div>
            <div class="sl" v-if="item.diagnosticDrugsVOList.length">
              x{{ item.diagnosticDrugsVOList[0].quantity }}
            </div>
          </div>
        </template>
      </div>
      <empty v-if="!list.length && isLoading" />

      <div v-if="!isLastPage" class="getmore" @click="getMore">加载更多</div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import empty from "@/com/empty.vue";
export default {
  components: { empty },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters([
      "dicts",
      "chiefComplaintTimeTypeList",
      "CHIEFCOMPLAINT_DEGREE", // 主诉程度
    ]),
  },
  watch: {
    info(newValue, oldValue) {
      this.init();
    },
  },
  data() {
    return {
      isLoading: false,
      list: [],
      pageNo: 1, //页码
      pageSize: 2,
      isLastPage: false,
    };
  },
  methods: {
    getMore() {
      this.pageNo++;
      this.getData();
    },
    clickItem(item, name) {
      switch (name) {
        case "quote":
          this.$emit("quote", item.diagnosticDrugsVOList);
          break;
      }
    },
    chiefComplaint(item) {
      let str = "";
      if (item.chiefComplaintTime) {
        str = `持续: ${item.chiefComplaintTime}`;
        let i = this.chiefComplaintTimeTypeList.find(
          (i) => i.value == item.chiefComplaintTimeType
        );
        str += i ? i.label : "";
      }

      if (item.chiefComplaintDegree) {
        let i = this.CHIEFCOMPLAINT_DEGREE.find(
          (i) => i.value == item.chiefComplaintDegree
        );
        str += ` 程度: ${i.label}`;
      }
      return str;
    },
    init() {
      this.isLastPage = false;
      this.list = [];
      this.pageNo = 1;
      this.isLoading = false;
      this.getData();
    },
    Disease_Type(diseaseTypeId) {
      let list = this.dicts["Disease_Type"] || [];
      let obj = list.find((i) => i.value == diseaseTypeId);
      if (obj) {
        return obj.string5 ? `疗程：${obj.string5 + obj.string5Unit + "~" + obj.string6 + obj.string6Unit}` : "";
      } else {
        return "";
      }
    },
    getData() {
      let obj = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,

        patientCode: this.info.patientCode,
        startTime: this.info.startDate,//  开始时间
        endTime: this.info.endDate, // 结束时间
        searchType: 1,
      };

      this.$store.commit("openSpin");
      this.api2("listPatientMainSymptomNew", obj).then((res) => {
        this.$store.commit("closeSpin");
        this.isLoading = true;
        this.list = this.list.concat(res.result);
        this.isLastPage = this.list.length >= res.total ? true : false;
      });
    },
  },

  created() {
    this.$store.dispatch("CHIEFCOMPLAINT_DEGREE");
    this.getData();
  },
};
</script>

<style lang="scss" >
.medical_record_list {
  background: #fff;
  border-radius: 5px 5px 0 0;
  height: 100%;
  display: flex;
  flex-direction: column;

  .getmore {
    cursor: pointer;
    text-align: center;
    line-height: 30px;
  }

  .medical_record_list_body {
    height: 100%;
    overflow-y: auto;
  }

  .title {
    height: 36px;
    line-height: 36px;
    background: #853a2f;
    text-align: center;
    color: #fff;
    border-radius: 5px 5px 0 0;
  }

  .m_r_l_item {
    border-bottom: 10px solid #f5f5f5;
    padding: 10px 15px;
    position: relative;

    &:last-child {
      border: none;
    }

    .fiexed_right {
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .hander {
      font-size: 13px;
      color: #666;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;
      margin-bottom: 5px;
    }

    .cf {
      position: relative;

      .value {
        padding-right: 30px;
      }

      .sl {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 22px;
        color: #f00;
        font-weight: bold;
      }
    }

    .item {
      line-height: 2em;

      &>div {
        display: inline-block;
      }

      .label {
        color: #999;
        width: 62px;
      }

      .other {
        font-size: 12px;
        background: #fffce0;
        display: inline-block;
        line-height: 16px;
        padding: 0 8px;
      }
    }

    .ivu-badge {
      margin: 0 10px 13px 0;
      height: 30px;
    }

    .symptom_name {
      display: inline-block;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      border-radius: 3px;
      overflow: hidden;
      font-size: 14px;
      background: #f2e6d4;
    }

    .line {
      display: inline-block;
      height: 30px;
      margin: 0 15px 0 5px;
      border-left: 1px solid #ccc;
    }

    .symptom_remove {

      .ivu-badge-count,
      span {
        background: #eee;
      }

      .ivu-badge-count {
        color: #333;
      }
    }

    .ivu-badge-count {
      height: 16px;
      line-height: 14px;
      font-size: 12px;
      padding: 0 2px;
      right: 0px;
      top: -8px;
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
    border-top: 1px solid #e5e5e5;
  }
}
</style>